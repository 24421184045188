@mixin font-sans-serif($fonts...) {
  font: {
    family:
      $fonts,
      "Segoe UI",
      Meiryo,
      system-ui,
      -apple-system,
      BlinkMacSystemFont,
      sans-serif;
  }
}

@mixin font-code($fonts...) {
  font: {
    family:
      $fonts,
      SFMono-Regular,
      Consolas,
      Menlo,
      monospace;
    size: 85%;
  }
}
