:root {
  --color-label: #000;
  --color-secondary-label: #30363d;

  --color-background: #fff;
  --color-secondary-background: #f6f8fa;

  --color-fill: #eaecef;
  --color-secondary-fill: #e1e4e8;

  --color-link: #000;
  --color-secondary-link: #30363d;

  @media (prefers-color-scheme: dark) {
    --color-label: #fff;
    --color-secondary-label: #8b949e;

    --color-background: #0d1117;
    --color-secondary-background: #21262d;

    --color-fill: #8b949e;
    --color-secondary-fill: #30363d;

    --color-link: #fff;
    --color-secondary-link: #8b949e;
  }
}

$color-label: var(--color-label);
$color-label-highlighted: var(--color-background);
$color-secondary-label: var(--color-secondary-label);

$color-background: var(--color-background);
$color-background-highlighted: var(--color-label);
$color-secondary-background: var(--color-secondary-background);

$color-fill: var(--color-fill);
$color-secondary-fill: var(--color-secondary-fill);

$color-link: var(--color-link);
$color-secondary-link: var(--color-secondary-link);
