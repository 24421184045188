@import "font";
@import "hr";
@import "colors";

* {
  margin: 0;
  padding: 0;

  // Chrome seems like not able to render `subpixel-antialiased` (which is default)
  // properly if font size is big, To have a consistency on a single page,
  // use `antialiased`.
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

// NOTE: These `height` are not really right implementation, which limits `html` and `body` rectangle to
// the one equal to `window.innerHeight` and contents are overflowed.
// However, this is least problematic implementation right now instead of using `100vh` or it require
// JavaScript to assign unknown `window.innerHeight` to `body`'s min-height.
body {
  // `modern-css-reset` sets `100vh` to `min-height` which doesn't work as expected on mobile Safari,
  // that is intentional behavior.
  min-height: unset;
}
html, body {
  height: 100%;
}

body {
  @include font-sans-serif();

  color: $color-label;
  background-color: $color-background;
}
// TODO: better way to specify default `::selection` state for `body` texts.
::selection {
  color: $color-label-highlighted;
  background-color: $color-background-highlighted;
}

a {
  color: $color-link;
  text-decoration: underline;
  text-underline-offset: 0.1em;
  text-decoration-thickness: 0.02em;

  &:hover {
    text-decoration: underline;
    text-decoration-thickness: 0.1em;
  }
}

code {
  @include font-code();

  word-break: break-all;
  background-color: $color-secondary-background;
  border-radius: 0.2em;
  padding: 0.2em 0.4em;
}

body {
  display: flex;
  flex-direction: column;

  > * {
    width: 70%;
    max-width: 790px;
    margin: auto;
  }

  > main {
    flex-grow: 1;
    margin: {
      top: 4em;
      bottom: 4em;
    }
    // NOTE: Safari workaround.
    // This is unclear though, without having `height` with one type of intrinsic sizes,
    // Safari will not calculate any relative hight for the content such as `.cover`.
    height: fit-content;
  }

  > header {
    flex-shrink: 0;
    height: 8em;
    display: flex;
    align-items: flex-end;
  }

  > footer {
    flex-shrink: 0;
    height: 4em;
    display: flex;
    align-items: flex-start;
    color: $color-secondary-label;
  }
}

.cover {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

article {
  a {
    color: $color-secondary-link;
  }

  p {
    margin: {
      top: 1em;
      bottom: 1em;
    }
    line-height: 150%;
  }

  h1, h2, h3, h4 {
    margin: {
      top: 2em;
      bottom: 1em;
    }
  }

  > header {
    margin: {
      top: 1em;
      bottom: 2em;
    }

    time {
      color: $color-secondary-label;
    }

    h1 {
      margin: {
        top: 0;
      }
      font-size: 2em;
    }
  }

  blockquote {
    padding: 1em 2em;
    border-left: 0.2em solid $color-fill;
  }

  ul, ol {
    padding-left: 2em;
  }

  figure {
    margin: {
      top: 1em;
      left: -2em;
      bottom: 1em;
      right: -2em;
    }
    padding: 2em;
    background-color: $color-secondary-background;
    overflow: auto;

    > img {
      margin: {
        left: auto;
        right: auto;
      }
    }

    > pre > code {
      padding: 0;
      background-color: none;
    }

    > table {
      width: 100%;
      border-collapse: collapse;

      td, th {
        border: {
          top: 1px solid $color-secondary-fill;
          bottom: 1px solid $color-secondary-fill;
        }
        padding: 0.4em;
      }
    }
  }

  hr {
    margin: {
      top: 2em;
      bottom: 2em;
    }
    border: none;

    &::before {
      @include hr();
    }
  }

  .footnotes {
    margin-top: 2em;

    &::before {
      margin-bottom: 2em;
      @include hr();
    }
  }
}
